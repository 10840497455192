<template>
  <div>
    <Tabs :activeTab="4" />
    <form id="projectSchemes">
      <div class="content-wrap-container">
      <div class="row">
        <div class="col-md-12">
          <table class="table">
            <thead>
              <tr>
                <th>No.</th>
                <th>Nombre del documento</th>
                <th></th>
                <th>Fecha de carga</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(type, index) in documentTypes" :key="index">
                <td>{{ index + 1 }}</td>
                <td v-if="type.isUploaded">
                  <a
                    @click="downloadDocument(type.id, type.extension)"
                    style="cursor: pointer"
                    class="text-primary"
                  >
                    {{ type.name }}
                  </a>
                </td>
                <td v-if="!type.isUploaded">{{ type.name }}</td>
                <td>
                  <i
                    class="pointer fa fa-upload"
                    v-on:click="showModal(type.id)"
                  ></i>
                  &nbsp;
                  <i
                    class="pointer fa fa-trash"
                    v-on:click="deleteDocument(type.id)"
                  ></i>
                </td>
                <td style="text-aling: left !important">
                  {{ type.updated_at }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      </div><!--end content-wrap-container-->
    </form>
    <modal-popup-schemes
      @refreshDocuments="refreshDocuments"
      :developer_id="developer_id"
      :document_type_id="document_type_id"
    />
  </div>
</template>

<script>
import Tabs from "../../components/Project/Tabs.vue";
import ModalPopupSchemes from "./Components/ModalPopupSchemes.vue";
import SourceTypes from "@/constants/SourceTypes";
import { getDocumentTypes } from "../../services/projectAPI.js";
import { alertError } from "../../helpers/alerts";
import { getDeveloperData } from "../../services/developerData";
import { deleteDocumentTypes } from "../../services/DocumentsAPI.js";
import {
  getUploadedDocument,
  downloadDocument,
} from "../../services/DocumentsAPI";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  components: { Tabs, ModalPopupSchemes },
  data() {
    return {
      loading: false,
      documentTypes: [],
      form: {
        rnc: "",
        business_name: "",
        dgii_status: "",
        person_type_id: "",
        address: "",
        state: "",
        phone1: "",
        phone2: "",
        legal_representation: "",
        position: "",
        email: "",
        office_phone: "",
        cellphone: "",
        total_assets: 1,
        total_liabilities: 2,
        current_assets: 3,
        current_liabilities: 4,
        total_passive: 5,
        heritage: 6,
      },
      developer_id: 0,
      document_type_id: 0,
    };
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
  },
  methods: {
    async refreshDocuments() {
      await this.getDocumentType();
    },
    handleSubmit() {},
    resetForm() {},
    showModal(documentIndex) {
      this.document_type_id = documentIndex;
      this.$root.$emit("bv::show::modal", "LoadFilesModal", "#btnShow");
    },
    async loadDeveloper() {
      let developer = await getDeveloperData();
      this.developer_id = developer.id;
    },
    async getDocumentType() {
      let data = await getDocumentTypes().catch(() => {
        alertError("Error al obtener los tipos de documentos.");
      });
      data = await this.getUploadedDocument(data);
      this.documentTypes = data.filter(
        (x) => x.source_type_category_id == SourceTypes.PlanosOEsquemas
      );
    },
    async deleteDocument(documentId) {
      await Swal.fire({
        title: "Esta seguro que desea eliminar el archivo?",
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        denyButtonText: `Cancelar`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteDocumentTypes(
            this.developer_id,
            documentId,
            this.projectId
          ).then(async (data) => {
            await this.refreshDocuments();
            Swal.fire({
              title: "¡Proceso exitoso!",
              text: data,
              icon: "success",
              confirmButtonText: "Listo",
            }).catch(() => {
              Swal.fire({
                title: "¡Error!",
                text: "Archivo no existe",
                icon: "error",
                confirmButtonText: "Volver",
              });
            });
          });
        }
      });
    },
    async getUploadedDocument(documents) {
      var data = await getUploadedDocument(
        SourceTypes.PlanosOEsquemas,
        this.projectId
      );
      documents.forEach((e) => {
        var document = data.documents.filter((x) => x.document_type_id == e.id);
        if (document.length > 0) {
          e.isUploaded = true;
          e.extension = document[0].extension;
          e.updated_at = moment(document[0].updated_at).format(
            "DD-MM-yyyy hh:mm a"
          );
        } else {
          e.isUploaded = false;
        }
      });
      return documents;
    },
    async downloadDocument(source_type, ext) {
      downloadDocument(source_type, ext, this.projectId);
    },
  },
  async mounted() {
    await this.loadDeveloper();
    await this.getDocumentType();
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
form .form-group {
  text-align: start;
}
table tr th,
table tr td {
  text-align: start;
}
</style>
